<template>
  <v-container>
     <v-confirm-dialog
      @accept="acceptDelete"
      ref="dialog"
    ></v-confirm-dialog>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          {{editedItem.id_user == -1 ? "Nuevo" : "Editar"}} Usuario
        </v-card-title>
        <v-card-text>
          <v-form v-model="isValid">
            <v-row v-if="editedItem.id_user == -1">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="editedItem.username"
                  label="Nombre del Usuario"
                  outlined
                  :rules="[rules.required, rules.min6]"
                  :error="firstTime[0]"
                  @change="used(0)"
                  @input="used(0)"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="editedItem.id_user == -1">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="editedItem.password"
                  label="Contraseña"
                  outlined
                  :rules="[rules.required, rules.min8]"
                  :error="firstTime[1]"
                  @change="used(1)"
                  @input="used(1)"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="editedItem.id_company"
                  label="Compañia"
                  :items="companies"
                  item-text="company_name"
                  item-value="id_company"
                  outlined
                  :error="editedItem.id_company == -1"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="reset">Cancelar</v-btn>
          <v-btn-save label="Guardar" text @click="save" :saving="saving"></v-btn-save>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPassword"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          Cambiar Contraseña
        </v-card-title>
        <v-card-text>
          <v-form v-model="isValidPassword">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="password"
                  label="Contraseña"
                  outlined
                  :rules="[rules.required, rules.min8]"
                  :error="firstTime[2]"
                  @change="used(2)"
                  @input="used(2)"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="resetPassword">Cancelar</v-btn>
          <v-btn-save label="Guardar" text @click="savePassword" :saving="savingPassword"></v-btn-save>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn color="primary" @click="dialog = true">Nueva Usuario</v-btn>
    <br>
    <br>
    <v-data-table
      :headers="headers"
      :items="users"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip-icon
          icon="mdi-key"
          text="Cambiar Contraseña"
          @click="changePassword(item)"
        ></v-tooltip-icon>
        <v-tooltip-icon
          icon="mdi-pencil-outline"
          text="Editar"
          @click="editItem(item)"
        ></v-tooltip-icon>
        <v-tooltip-icon
          icon="mdi-trash-can-outline"
          text="Eliminar"
          @click="deleteItem(item)"
        ></v-tooltip-icon>
      </template> 
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import VBtnSave from "@/components/VBtnSave";
import VTooltipIcon from "@/components/VTooltipIcon";
import VConfirmDialog from "@/components/VConfirmDialog";

export default {
  components:{
    VBtnSave,
    VTooltipIcon,
    VConfirmDialog
  },
  data:() => ({
    rules: {
      required: v => !!v || 'Obligatorio.',
      min: v => v.length >= 2 || 'Mínimo 2 Caracteres',
      min6: v => v.length >= 6 || 'Mínimo 8 Caracteres',
      min8: v => v.length >= 8 || 'Mínimo 8 Caracteres',
    },
    firstTime:[true,true,true],
    dialog:false,
    users:[],
    headers:[
      { 
        text: "Nombre del Usuario",
        align: "left",
        sortable: true,
        value: "username",
      },
      { 
        text: "Compañia",
        align: "left",
        sortable: true,
        value: "company",
      },
      { 
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
    isValid:false,
    editedItem:{
      id_user:-1,
      id_company: -1,
      company:"",
      username:"",
      password:"",
    },
    defaultItem:{
      id_user:-1,
      id_company: -1,
      username:"",
      company:"",
      password:"",
    },
    saving: false,
    loading: false,
    id_user:-1,
    password:"",
    dialogPassword:false,
    savingPassword:false,
    isValidPassword:false,
    showPassword:false,
    companies:[],
  }),
  mounted(){
    this.getUsers();
    this.getCompanies();
  },
  methods:{
    initialize(){
      this.getUsers();
      this.getCompanies();
    },
    used(pos){
      this.firstTime[pos] = false;
    },
    getUsers(){
      this.loading = true;

      axios
      .get("/Users")
      .then(response => {
        this.users = response.data;
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    getCompanies(){
      axios
      .get("/Company")
      .then(response => {
        this.companies = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    reset(){
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = false;
      this.firstTime = [true,true,true];
      this.showPassword = false;
    },
    editItem(item){
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.firstTime = [false,false,false];
    },
    deleteItem(item){
      this.id_user = item.id_user;
      this.$refs.dialog.setMessage("Confirmación de Borrado","¿Esta seguro que desea eliminar este registro? Esta acción es permanente y no se puede deshacer.");
      this.$refs.dialog.setText("Aceptar", "Cancelar");
      this.$refs.dialog.open();
    },
    changePassword(item){
      this.id_user = item.id_user;
      this.firstTime = [true,true,true];
      this.dialogPassword = true;
    },
    savePassword(){
      if(!this.isValidPassword) return;

      this.savingPassword = true;
      axios
      .put("/Users/UpdatePassword",{
        id_user: this.id_user,
        password: this.password,
      })
      .then(response => {
        this.resetPassword();
      })
      .catch(error => {
        this.savingPassword = false;
        console.log(error);
      })
    },
    resetPassword(){
      this.id_user = -1;
      this.password = "";
      this.dialogPassword = false;
      this.savingPassword = false;
      this.showPassword = false;
    },
    acceptDelete(){
      axios
      .delete("/Users/"+this.id_user)
      .then(() => {
        this.getUsers();
      })
      .catch(error => {
        console.log(error);
      });
    },
    save(){
      if(!this.isValid) return;

      this.saving = true;

      if(this.editedItem.id_user == -1){
        axios
        .post("/Users",{
          username: this.editedItem.username,
          password: this.editedItem.password,
          id_company: this.editedItem.id_company
        })
        .then(() => {
          this.saving = false;
          this.reset();
          this.getUsers();
        })
        .catch(error => {
          this.saving = false;
          console.log(error);
        });
      }
      else{
        axios
        .put("/Users",{
          id_user: this.editedItem.id_user,
          id_company: this.editedItem.id_company
        })
        .then(() => {
          this.saving = false;
          this.reset();
          this.getUsers();
        })
        .catch(error => {
          this.saving = false;
          console.log(error);
        });
      }
    }
  }
}
</script>