<template>
  <v-container>
     <v-confirm-dialog
      @accept="acceptDelete"
      ref="dialog"
    ></v-confirm-dialog>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          {{editedItem.id_company == -1 ? "Nueva" : "Editar"}} Compañia 
        </v-card-title>
        <v-card-text>
          <v-form v-model="isValid">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="editedItem.company_name"
                  label="Nombre de la Compañia"
                  outlined
                  :rules="[rules.required, rules.min]"
                  :error="firstTime[0]"
                  @change="used(0)"
                  @input="used(0)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-checkbox
                  v-model="editedItem.custom_place"
                  :label="'¿Usar Tipo de Negocio Personalizado?: '+(editedItem.custom_place ? 'Si' : 'No')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="!editedItem.custom_place">
                <v-autocomplete
                  outlined
                  v-model="editedItem.id_google_places_type"
                  label="Tipo de Compañia"
                  item-value="id_google_places_type"
                  item-text="type_name"
                  :items="types"
                  :error="editedItem.id_google_places_type == -1 || editedItem.id_google_places_type == null"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-else>
                <v-text-field
                  v-model="editedItem.custom_place_type"
                  outlined
                  label="Tipo de Compañia Personalizado"
                  :rules="[rules.required, rules.min]"
                  :error="firstTime[1]"
                  @change="used(1)"
                  @input="used(1)"
                >
                </v-text-field>               
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-checkbox
                  v-model="editedItem.other_branches_adversaries"
                  :label="'¿Ver otras sucursales como competidores?: '+(editedItem.other_branches_adversaries ? 'Si' : 'No')"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="reset">Cancelar</v-btn>
          <v-btn-save label="Guardar" text @click="save" :saving="saving"></v-btn-save>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn color="primary" @click="dialog = true">Nueva Compañia</v-btn>
    <br>
    <br>
    <v-data-table
      :headers="headers"
      :items="companies"
    >
      <template v-slot:item.other_branches_adversaries="{ item }">
        {{item.other_branches_adversaries ? 'Si' : 'No'}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip-icon
          icon="mdi-pencil-outline"
          text="Editar"
          @click="editItem(item)"
        ></v-tooltip-icon>
        <v-tooltip-icon
          icon="mdi-trash-can-outline"
          text="Eliminar"
          @click="deleteItem(item)"
        ></v-tooltip-icon>
      </template> 
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import VBtnSave from "@/components/VBtnSave";
import VTooltipIcon from "@/components/VTooltipIcon";
import VConfirmDialog from "@/components/VConfirmDialog";

export default {
  components:{
    VBtnSave,
    VTooltipIcon,
    VConfirmDialog
  },
  data:() => ({
    rules: {
      required: v => !!v || 'Obligatorio.',
      min: v => (v != null && v.length >= 2) || 'Mínimo 2 Caracteres',
    },
    firstTime:[true,true],
    dialog:false,
    companies:[],
    types:[],
    headers:[
      { 
        text: "Nombre de la Compañia",
        align: "left",
        sortable: true,
        value: "company_name",
        width: "40%"
      },
      { 
        text: "Tipo de la Compañia",
        align: "left",
        sortable: true,
        value: "company_type",
        width: "20%"
      },
      { 
        text: "¿Considerar otras sucursales como competencia?",
        align: "left",
        sortable: true,
        value: "other_branches_adversaries",
        width: "20%"
      },
      { 
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
    isValid:false,
    editedItem:{
      id_company:-1,
      id_google_places_type:null,
      custom_place: false,
      custom_place_type: null,
      other_branches_adversaries:false,
      company_name:null,
    },
    defaultItem:{
      id_company:-1,
      id_google_places_type:null,
      custom_place: false,
      custom_place_type: null,
      other_branches_adversaries:false,
      company_name:null,
    },
    saving: false,
    loading: false,
    id_company:-1,
  }),
  mounted(){
    this.initialize();
  },
  methods:{
    initialize(){
      this.getCompanies();
      this.getTypes();
    },
    used(pos){
      this.firstTime[pos] = false;
    },
    getTypes(){
      axios
      .get("/Google")
      .then(response => {
        this.types = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getCompanies(){
      this.loading = true;

      axios
      .get("/Company")
      .then(response => {
        this.companies = response.data;
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    reset(){
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = false;
      this.firstTime = [true,true];
    },
    editItem(item){
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.firstTime = [false,false];
      if(!item.custom_place) this.firstTime[1] = true;
    },
    deleteItem(item){
      this.id_company = item.id_company;
      this.$refs.dialog.setMessage("Confirmación de Borrado","¿Esta seguro que desea eliminar este registro? Esta acción es permanente y no se puede deshacer.");
      this.$refs.dialog.setText("Aceptar", "Cancelar");
      this.$refs.dialog.open();
    },
    acceptDelete(){
      axios
      .delete("/Company/"+this.id_company)
      .then(() => {
        this.getCompanies();
      })
      .catch(error => {
        console.log(error);
      });
    },
    save(){
      if(!this.isValid) return;

      this.saving = true;

      if(this.editedItem.id_company == -1){
        axios
        .post("/Company",{
          company_name: this.editedItem.company_name,
          custom_place: this.editedItem.custom_place,
          custom_place_type: (this.editedItem.custom_place ? this.editedItem.custom_place_type: null),
          id_google_places_type: (!this.editedItem.custom_place ? this.editedItem.id_google_places_type : null),
          other_branches_adversaries: this.editedItem.other_branches_adversaries
        })
        .then(() => {
          this.saving = false;
          this.reset();
          this.getCompanies();
        })
        .catch(error => {
          this.saving = false;
          console.log(error);
        });
      }
      else{
        axios
        .put("/Company",{
          id_company: this.editedItem.id_company,
          company_name: this.editedItem.company_name,
          custom_place: this.editedItem.custom_place,
          custom_place_type: (this.editedItem.custom_place ? this.editedItem.custom_place_type: null),
          id_google_places_type: (!this.editedItem.custom_place ? this.editedItem.id_google_places_type : null),
          other_branches_adversaries: this.editedItem.other_branches_adversaries
        })
        .then(() => {
          this.saving = false;
          this.reset();
          this.getCompanies();
        })
        .catch(error => {
          this.saving = false;
          console.log(error);
        });
      }
    }
  }
}
</script>