<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="reset"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{editedItem.id_form1 == -1?'Nuevo':'Editar'}} Registro</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="save"
              :disabled="saving"
              :loading="saving"
            >
              Guardar
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  :width="2"
                  :size="24"
                  color="white"
                ></v-progress-circular>
              </template>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <br>
          <v-form v-model="isValid">
            <v-row>
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <v-text-field
                  outlined
                  counter
                  :maxlength="255"
                  label="Código de la Sucursal"
                  v-model="editedItem.branch_code"
                  :rules="[rules.required,rules.min]"
                  :error="firstTime[0]"
                  @keydown="firstTime[0] = false"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="9" lg="9" xl="9">
                <v-text-field
                  outlined
                  counter
                  :maxlength="1024"
                  label="Nombre de la Sucursal"
                  v-model="editedItem.branch_name"
                  :rules="[rules.required,rules.min]"
                  :error="firstTime[1]"
                  @keydown="firstTime[1] = false"

                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-currency-field
                  outlined
                  v-model="editedItem.income"
                  label="Ingreso Total por Mes"
                  :options="currencyOptions"
                  :rules="[rules.required]"
                  :error="firstTime[2]"
                  @input="firstTime[2] = false"

                >
                </v-currency-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                <v-autocomplete
                  outlined
                  v-model="editedItem.year"
                  label="Año"
                  :items="years"
                  @change="editedItem.month = -1"
                  :error="editedItem.year == -1"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                <v-autocomplete
                  outlined
                  v-model="editedItem.month"
                  label="Mes"
                  item-value="id"
                  item-text="value"
                  :items="months"
                  :error="editedItem.month == -1"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-number-field
                  v-model="editedItem.coordinate[1]"
                  outlined
                  label="Latitud"
                  :rules="[rules.required, rules.number, rules.latitud]"
                  @input="moveCenter"
                  @change="moveCenter"
                ></v-number-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-number-field
                  v-model="editedItem.coordinate[0]"
                  outlined
                  label="Longitud"
                  :rules="[rules.required, rules.number, rules.longitud]"
                  @input="moveCenter"
                  @change="moveCenter"
                ></v-number-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12">
              <vl-map
                :load-tiles-while-animating="true"
                :load-tiles-while-interacting="true"
                data-projection="EPSG:4326" style="height: 500px"
                @click="editedItem.coordinate = $event.coordinate"
              >
                <vl-view
                  :zoom.sync="zoom"
                  :center.sync="center"
                  :rotation.sync="rotation"
                ></vl-view>
                
                <vl-layer-tile>
                  <vl-source-osm></vl-source-osm>
                </vl-layer-tile>

                <vl-feature>
                  <template>
                    <vl-geom-point :coordinates="editedItem.coordinate"></vl-geom-point>
                    <vl-style-box>
                      <vl-style-icon src="img/flag.png" :scale="0.5" :anchor="[0.1, 0.95]" :size="[128, 128]"></vl-style-icon>
                    </vl-style-box>
                  </template>
                </vl-feature>
              </vl-map>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn color="blue" text @click="locateMe"><v-icon>mdi-target</v-icon> Usar mi Localización Actual</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-confirm-dialog
      @accept="acceptDialog"
      ref="dialog"
    ></v-confirm-dialog>
    <v-btn color="primary" @click="dialog = true">Nuevo Registro</v-btn>
    <br>
    <br>
    <v-data-table
      :items="forms"
      :headers="headers"
      :loading="loading"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:item.gps="{ item }">
        {{item.coordinate[1].toFixed(6)}}, {{item.coordinate[0].toFixed(6)}}
      </template>
      <template v-slot:item.date="{ item }">
        {{ monthsList.find(m => m.id == item.month).value}} {{item.year}}
      </template>
      <template v-slot:item.income="{ item }">
        {{moneyFormat(item.income)}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip-icon
          v-if="id_user == item.id_user"
          icon="mdi-sync"
          text="Actualizar Competencia"
          @click="updateItem(item)"
        ></v-tooltip-icon>
        <v-tooltip-icon
          v-if="id_user == item.id_user"
          icon="mdi-pencil-outline"
          text="Editar"
          @click="editItem(item)"
        ></v-tooltip-icon>
        <v-tooltip-icon
          v-if="id_user == item.id_user"
          icon="mdi-trash-can-outline"
          text="Eliminar"
          @click="deleteItem(item)"
        ></v-tooltip-icon>
      </template> 
    </v-data-table>  
  </v-container>
</template>

<script>
import VCurrencyField from "@/components/VCurrencyField";
import VConfirmDialog from "@/components/VConfirmDialog";
import VNumberField from "@/components/VNumberField";
import VTooltipIcon from "@/components/VTooltipIcon"
import axios from "axios";
import { mapState } from 'vuex'

export default {
  components:{
    VCurrencyField,
    VConfirmDialog,
    VNumberField,
    VTooltipIcon,
  },
  computed: {
    ...mapState({
        id_user: 'id_user'
    }),
    years(){
      var diff = (new Date()).getFullYear() - this.minYear;
      var years = [];
      for (let i = 0; i <= diff; i++) {        
        years.unshift(this.minYear + i);
      }
      return years;
    },
    months(){
      if((new Date()).getFullYear() == this.editedItem.year){
        return this.monthsList.filter(m => m.id <= ((new Date()).getMonth() +1 ));
      }
      else return this.monthsList;
    }
  },
  data: () => ({
    firstTime:[true,true,true],
    rules: {
      required: v => !!v || 'Obligatorio.',
      min: v => v.length >= 2 || 'Mínimo 2 Caracteres',
      number: v => !isNaN(parseFloat(v)) || 'No es un Número Valido',
      latitud: v => Math.abs(isNaN(parseFloat(v))? 91 : parseFloat(v)) <= 90 || 'No es una Coordenada Valida',
      longitud: v => Math.abs(isNaN(parseFloat(v))? 181 : parseFloat(v)) <= 180 || 'No es una Coordenada Valida' 
    },
    headers:[
      { 
        text: "Coordenadas Geográficas",
        align: "center",
        sortable: true,
        value: "gps",
      },
      { 
        text: "Código de la Sucursal",
        align: "center",
        sortable: true,
        value: "branch_code",
      },
      { 
        text: "Nombre de la Sucursal",
        align: "center",
        sortable: true,
        value: "branch_name",
      },
      { 
        text: "Fecha",
        align: "center",
        sortable: true,
        value: "date",
      },
      { 
        text: "Ingreso Total por Mes",
        align: "center",
        sortable: true,
        value: "income",
      },
      { 
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
    monthsList:[
      {
        id:12,
        value:"Diciembre"
      },
      {
        id:11,
        value:"Noviembre"
      },
      {
        id:10,
        value:"Octubre"
      },
      {
        id:9,
        value:"Septiembre"
      },
      {
        id:8,
        value:"Agosto"
      },
      {
        id:7,
        value:"Julio"
      },
      {
        id:6,
        value:"Junio"
      },
      {
        id:5,
        value:"Mayo"
      },
      {
        id:4,
        value:"Abril"
      },
      {
        id:3,
        value:"Marzo"
      },
      {
        id:2,
        value:"Febrero"
      },
      {
        id:1,
        value:"Enero"
      },
    ],
    currencyOptions:{
      locale: 'en',
      currency: 'USD',
    },
    forms:[],
    dialog:false,
    minYear:2018,
    zoom: 18,
    center: [-98.19809943437578,19.043676321161357],
    defaultCenter: [-98.19809943437578,19.043676321161357],
    rotation: 0,
    errores:[],
    editedItem:{
      id_form1:-1,
      branch_code:"",
      branch_name:"",
      year:-1,
      month:-1,
      income:null,
      coordinate: [-98.19809943437578,19.043676321161357],
    },
    defaulteditedItem:{
      id_form1:-1,
      branch_code:"",
      branch_name:"",
      year:-1,
      month:-1,
      income:null,
      coordinate: [-98.19809943437578,19.043676321161357],
    },
    isValid:false,
    loading:false,
    id_form1:-1,
    saving:false,
    mode:0,
  }),
  mounted(){
    this.getForm1s();
  },
  methods:{
    initialize(){
      this.getForm1s();
    },
    async getLocation() {
      return new Promise((resolve, reject) => {

        if(!("geolocation" in navigator)) {
          reject(new Error('Geolocation is not available.'));
        }

        navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, err => {
          reject(err);
        });

      });
    },
    async locateMe() {
      try {
        var location = await this.getLocation();
        this.editedItem.coordinate = [location.coords.longitude, location.coords.latitude];
        this.center = this.editedItem.coordinate;
      } catch(e) {
        console.log(e);
      }
    },
    getForm1s(){
      this.loading = true;
      axios
      .get("/Form1")
      .then(response => {
        this.loading = false;
        this.forms = response.data;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    reset(){
      this.dialog = false;
      this.editedItem = Object.assign({},this.defaulteditedItem);
      this.editedItem.coordinate = JSON.parse(JSON.stringify(this.defaultCenter));
      this.center = JSON.parse(JSON.stringify(this.defaultCenter));
      this.zoom = 18;
      this.firstTime = [true,true,true];
    },
    editItem(item){
      this.dialog = true;
      this.editedItem = Object.assign({},item);
      this.editedItem.coordinate = JSON.parse(JSON.stringify(item.coordinate));
      this.center = JSON.parse(JSON.stringify(item.coordinate));
      this.zoom = 18;
      this.firstTime = [false,false,false];
    },
    deleteItem(item){
      this.mode = 0;
      this.id_form1 = item.id_form1;
      this.$refs.dialog.setMessage("Confirmación de Borrado","¿Está seguro de que desea eliminar este registro? Esta acción es permanente y no se puede deshacer.");
      this.$refs.dialog.setText("Aceptar", "Cancelar");
      this.$refs.dialog.open();
    },
    updateItem(item){
      this.mode = 1;
      this.id_form1 = item.id_form1;
      this.$refs.dialog.setMessage("Confirmación de Actualización de Competidores","¿Está seguro de que desea realizar una actualización de competidores? Esta acción conlleva un costo asociado.");
      this.$refs.dialog.setText("Aceptar", "Cancelar");
      this.$refs.dialog.open();
    },
    acceptDialog(){
      if(this.mode == 0){
        axios
        .delete("/Form1/"+this.id_form1)
        .then(() => {
          this.getForm1s();
        })
        .catch(error => {
          console.log(error);
        });
      }
      else if(this.mode == 1){
        axios
        .put("/Form1/UpdateAdversaries/"+this.id_form1)
        .then(() => {
          this.getForm1s();
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    save(){
      if(this.isValid){
        this.saving = true;
        if(this.editedItem.id_form1 == -1){
          axios
          .post("/Form1",{
            branch_code: this.editedItem.branch_code,
            branch_name: this.editedItem.branch_name,
            year: this.editedItem.year,
            month: this.editedItem.month,
            income: this.editedItem.income,
            latitude: this.editedItem.coordinate[1],
            longitude: this.editedItem.coordinate[0],
          })
          .then( () => {
            this.saving = false;
            this.reset();
            this.getForm1s();
          })
          .catch(error => {
            this.saving = false;
            console.log(error);
          })
        }
        else{
          axios.
          put("/Form1",{
            id_form1: this.editedItem.id_form1,
            branch_code: this.editedItem.branch_code,
            branch_name: this.editedItem.branch_name,
            year: this.editedItem.year,
            month: this.editedItem.month,
            income: this.editedItem.income,
            latitude: this.editedItem.coordinate[1],
            longitude: this.editedItem.coordinate[0],
          })
          .then( () => {
            this.saving = false;
            this.reset();
            this.getForm1s();
          })
          .catch(error => {
            this.saving = false;
            console.log(error);
          });

        }
      }
      //Pintar Errores
      /*
      else{
        
      }
      */
    },
    moneyFormat(number,prefix = "$"){
      var parts = number.toFixed(2).split(".");
      parts[0] = parts[0].split("").reverse().join("").replace(/(.{3})/g,"$1,").split("").reverse().join("");
      if(parts[0][0] == ",") parts[0] = parts[0].substring(1);
      return prefix + parts.join(".");
    },
    moveCenter(){
      this.center = JSON.parse(JSON.stringify(this.editedItem.coordinate));
    }
  }
}
</script>