<template>
  <VTextField
    ref="input"
    :label="label"
    :outlined="outlined"
    :rules="rules"
    :error="error"
    v-currency="options"
    :value="formattedValue"
    @change="onChange"
    @input="onInput"
  />
</template>

<script>
export default {
  name: "VCurrencyField",
  props: {
    label: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null
    },
    options: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      formattedValue: null
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    }
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value);
    },
    onInput(value) {
      this.$emit("input", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
    onChange(value) {
      this.$emit("change", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    }
  }
};
</script>
