import axios from "axios";

export default {
  install(Vue, options) {
    Vue.prototype.$utils = {
      arrays:{
        unique: function (array) {
          function onlyUnique(value, index, self) { 
            return self.indexOf(value) === index;
          }
          return array.filter( onlyUnique ); 
        },
        equals: function (array1, array2) {
          return array1.sort().toString() == array2.sort().toString();
        },
        groupBy: function(array, key, includeObjects = false) {
          var result = [];
          array.forEach(element => {
            var index = result.findIndex(e => {
              return e.key == element[key];
            });
            if(index == -1){
              var obj = {
                key: element[key],
                count: 1,
                items: [],
              }
              if(includeObjects) obj.items.push(element);
              result.push(obj);
            }
            else{
              result[index].count += 1;
              if(includeObjects) result[index].items.push(element);
            }

          });
          return result;
        },
        move: function(array, fromIndex, toIndex) {
          var element = array[fromIndex];
          array.splice(fromIndex, 1);
          array.splice(toIndex, 0, element);
        },
        average: function (array) {
          return (array.reduce( ( p, c ) => p + c, 0 ) / array.length);
        }
      },
      strings:{
        removeAt: function (string,pos) {
          var chars = string.split('');
          chars.splice(pos,1);
          return chars.join('');
        },
        truncate: function(string, length){
          if(string.length <= length) return string;
          else{
            return string.substring(0,length) + '...';
          }
        },
        partialMayus: function(string,start,length){
          if(string == null || string.length == 0) return string;
          else if(string.length <= length) return string.toUpperCase();
          else{
            var mayus = string.substring(start,length).toUpperCase();
            return mayus + string.substring(start+length,string.length);
          }
        },
      },
      numbers:{
        validFloat: function (value, defaultValue = 0.0) {
          return isNaN(parseFloat(value)) ? defaultValue : parseFloat(value);
        },
        randomInteger: function(){
          try{
            var array = new Int32Array(1);
            window.crypto.getRandomValues(array);
            return Math.abs(array[0]);  
          }
          catch(error){
            return Math.floor(Math.random() * 2147483646) + 1 
          }
        }
      },
      isValid: function (variable){
        return (typeof variable !== 'undefined' && variable !== null);
      },
      axios:{
        downloadFile: function (url,method,filename,mime, data = null) {
          axios({
            url: url,
            method: method,
            responseType: 'blob',
            data: data,
          })
          .then(response => {
            let blob = new Blob([response.data], { type: mime })
            let a = document.createElement("a") 
            let blobURL = URL.createObjectURL(blob)
            a.download = filename
            a.href = blobURL
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          })
          .catch(error => {
            console.log(error);
          })
        }
      },
      validation:{
        validString : function (value, name, min, max = Infinity){
          var self = Vue.prototype.$utils;
          if(!self.isValid(value)) return name+" no es un valor valido";
          else{
            if(isFinite(max)){
              if(value.length < min || value.length > max) return name+" debe de tener entre "+min+" y "+max+" caracteres";
              else return null;
            }
            else{
              if(value.length < min) return name+" debe de tener más de "+min+" caracteres";
              else return null;
            }
          }
        }
      }
    }
  }
};