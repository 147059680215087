// https://vuex.vuejs.org/en/mutations.html

export default {
  auth_request (state) {
    state.authStatus = 'loading'
  },
  auth_success (state, { token, user, id_user, is_admin}) {
    state.authStatus = 'success'
    state.token = token
    state.user = user
    state.id_user = id_user
    state.is_admin = is_admin
  },
  auth_error (state) {
    state.authStatus = 'error'
  },
  logout (state) {
    state.authStatus = ''
    state.token = ''
    state.id_user = -1
    state.is_admin = -1
  },
}
