<template>
  <v-container>
      <v-tabs v-model="tab" background-color="white" color="blue" centered icons-and-text>
          <v-tab href="#tab-1" @click="updateForm1()">
            Registros
            <v-icon>mdi-file-document-outline</v-icon>
          </v-tab>

          <v-tab href="#tab-2" @click="updateAdversaries()">
            Competidores
            <v-icon>mdi-boxing-glove</v-icon>
          </v-tab>

          <v-tab href="#tab-3" v-if="is_admin" @click="updateCompany()">
            Compañias
            <v-icon>mdi-domain</v-icon>
          </v-tab>

          <v-tab href="#tab-4" v-if="is_admin" @click="updateUser()">
            Usuarios
            <v-icon>mdi-shield-account-outline</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :key="1" :value="'tab-1'">
            <v-card flat>
              <v-card-text>
                <v-form1 ref="form1"></v-form1>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'">
            <v-card flat>
              <v-card-text>
                <v-adversaries ref="adversaries"></v-adversaries>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="'tab-3'">
            <v-card flat>
              <v-card-text>
                <v-companies ref="companies"></v-companies>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'">
            <v-card flat>
              <v-card-text>
                <v-users ref="users"></v-users>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
  </v-container>
</template>

<script>
import Form1 from './Form1';
import Companies from './Companies';
import Users from './Users';
import Adversaries from './Adversaries';
import { mapState } from 'vuex'

export default {
  components:{
    'v-form1':Form1,
    'v-companies':Companies,
    'v-users':Users,
    'v-adversaries': Adversaries
  },
  data: () => ({
    tab:0,
  }),
  computed: {
    ...mapState({
        is_admin: 'is_admin'
    }),
  },
  methods:{
    updateForm1(){
      if(typeof this.$refs.form1 !== 'undefined') this.$refs.form1.initialize();
    },
    updateAdversaries(){
      if(typeof this.$refs.adversaries !== 'undefined') this.$refs.adversaries.initialize();
    },
    updateCompany(){
      if(typeof this.$refs.companies !== 'undefined') this.$refs.companies.initialize();
    },
    updateUser(){
      if(typeof this.$refs.users !== 'undefined') this.$refs.users.initialize();
    }
  }
}
</script>