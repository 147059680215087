<template>
  <VTextField
    ref="input"
    :label="label"
    :outlined="outlined"
    :error="error"
    v-model="textValue"
    :rules="rules"
    @keypress="preventInput"
    @change="onChange"
    @input="onInput"
  />
</template>

<script>
export default {
  name: "VNumberField",
  props: {
    label: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null
    },
    rules: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      textValue: null
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    }
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.textValue = value+"";
    },
    onInput(value) {
      var number = this.safeValue(this.textValue);
      this.textValue = number == null ? "" : (value+"");
      this.$emit("input", (number == null ? 0: number));
    },
    onChange(value) {
      var number = this.safeValue(this.textValue);
      this.textValue = number == null ? "" : (value+"");
      this.$emit("change", (number == null ? 0: number));
    },
    safeValue(number){
      if(isNaN(parseFloat(number))) return null;
      else return parseFloat(number)
    },
    preventInput($event){
      var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      
      if(keyCode == 45){
        if(this.textValue.indexOf('-') >= 0) $event.preventDefault();
      }

      else if(keyCode == 46){
        if(this.textValue.indexOf('.') == 0) $event.preventDefault();
      }

      else{
        if(keyCode < 48 || keyCode > 57){
          $event.preventDefault();
        }
      }
    },
  }
};
</script>