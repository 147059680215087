<template>
<v-container>
  <v-row>
    <v-col cols="12">
      <vl-map
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true"
        data-projection="EPSG:4326" style="height: 600px"
      >
        <vl-view
          :center.sync="center"
          :rotation.sync="rotation"
          :resolution.sync="resolution"
        ></vl-view>
        <vl-layer-tile>
          <vl-source-osm></vl-source-osm>
        </vl-layer-tile>
        <vl-interaction-select :features.sync="selectedFeatures">
          <template slot-scope="select">
            
            <vl-style-box>
            <vl-style-stroke color="#423e9e" :width="7"></vl-style-stroke>
            <vl-style-fill :color="[254, 178, 76, 0.7]"></vl-style-fill>
            <vl-style-circle :radius="5">
              <vl-style-stroke color="#423e9e" :width="7"></vl-style-stroke>
              <vl-style-fill :color="[254, 178, 76, 0.7]"></vl-style-fill>
            </vl-style-circle>
          </vl-style-box>
          <vl-style-box :z-index="1">
            <vl-style-stroke color="#d43f45" :width="2"></vl-style-stroke>
            <vl-style-circle :radius="5">
              <vl-style-stroke color="#d43f45" :width="2"></vl-style-stroke>
            </vl-style-circle>
          </vl-style-box>


            <vl-overlay class="feature-popup" v-for="(feature, idx) in select.features" :key="'info'+idx" :id="idx" :position="pointOnSurface(feature.geometry)" :auto-pan="true" :auto-pan-animation="{ duration: 300 }">
              <template>
                <v-card v-if="typeof feature.properties.adversaries === 'undefined'">
                  <v-card-title>{{feature.properties.adversary_name}}</v-card-title>
                  <v-card-text>
                    {{feature.properties.address}}
                    <star-rating :increment="0.01" :rating="feature.properties.rating" :star-size="40"  read-only></star-rating>
                  </v-card-text>
                </v-card>
                <v-card v-else>
                  <v-card-title>{{feature.properties.branch_code}} - {{feature.properties.branch_name}}</v-card-title>
                  <v-card-text>
                    {{feature.properties.address}}
                    <star-rating :increment="0.01" :rating="feature.properties.rating" :star-size="40"  read-only></star-rating>
                    Competidores: {{feature.properties.adversaries.length}}
                  </v-card-text>
                </v-card>
              </template>
            </vl-overlay>
          </template>
        </vl-interaction-select>

        <template v-for="(branch,idx) in branchs">
          <vl-feature v-for="(adversary,idx2) in branch.adversaries" :key="'adversary-'+idx+'-'+idx2" :properties="adversary">
            <template>
              <vl-geom-point :coordinates="adversary.coordinate"></vl-geom-point>
              <vl-style-box>
                <vl-style-icon src="img/flag_red.png" :scale="0.5" :anchor="[0.1, 0.95]" :size="[128, 128]"></vl-style-icon>
              </vl-style-box>
            </template>
          </vl-feature>
        </template>

        <vl-feature v-for="(branch,idx) in branchs" :key="'branch-'+idx" :properties="branch">
          <template>
            <vl-geom-point :coordinates="branch.coordinate"></vl-geom-point>
            <vl-style-box>
              <vl-style-icon src="img/flag.png" :scale="0.5" :anchor="[0.1, 0.95]" :size="[128, 128]"></vl-style-icon>
            </vl-style-box>
          </template>
        </vl-feature>
      </vl-map>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from "axios";
import { findPointOnSurface } from 'vuelayers/lib/ol-ext'
import StarRating from 'vue-star-rating'

export default {
  components:{
    StarRating
  },
  data:() => ({
    branchs: [],
    selectedFeatures:[],
    zoom: 14,
    rotation: 0,
    center: [0,0],
    resolution: 9,
  }),
  mounted(){
    this.initialize();
  }, 
  methods:{
    resolutionM(){
      console.log(this.resolution);
    },
    pointOnSurface: findPointOnSurface,
    branchName: branch => "[" + branch.branch_code + "] - " + branch.branch_name,
    initialize(){
      this.getAdversaries();
    },
    getConfig(rating){
      return {
        rating: rating,
        style: {
            fullStarColor: '#ed8a19',
            emptyStarColor: '#737373',
            starWidth: 10,
            starHeight: 10
        }
      }
    },
    getAdversaries(){
      axios
      .get("/Adversary")
      .then(response => {
        this.branchs = response.data;
        var longs = [];
        var lats = [];
        response.data.forEach(branch => {
          longs.push(branch.coordinate[0]);
          lats.push(branch.coordinate[1]);
        });
        this.center = [0,0];
        this.center[0] = this.$utils.arrays.average(longs);
        this.center[1] = this.$utils.arrays.average(lats);
        var distances = [];
        response.data.forEach(branch => {
          var distance = Math.sqrt(Math.pow((this.center[0] - branch.coordinate[0]),2) + Math.pow((this.center[1] - branch.coordinate[1]),2))
          distances.push(distance);
        });
        this.resolution = this.$utils.arrays.average(distances) * 650;
      })
      .catch(error => {
        console.log(error);
      })
    },
  }
}
</script>